<template>
    <div class="d-flex flex-row align-items-center">
        <template v-if="!btnView">
            <IconCrypto :coinname="tFrom.toLowerCase()" color="color" format="svg" class="mr-1" width="18"/>
            <IconCrypto :coinname="tTo.toLowerCase()" color="color" format="svg" class="mr-1" width="18"/>
            <span>{{ tFrom.toUpperCase() }}/{{ tTo.toUpperCase() }}</span>
        </template>
        <template v-if="btnView">
            <b-button size="min" variant="gray">
                <IconCrypto :coinname="tFrom.toLowerCase()" color="color" format="svg" class="mr-1" width="18"/>
                {{tFrom}}
            </b-button>
            <svg class="mx-1" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 9.33333L0 9.33333L6.66667 16L6.66667 12H12V9.33333Z" fill="#D6D6E0"/>
                <path d="M6.66667 0L6.66667 4L1.33333 4L1.33333 6.66667L13.3333 6.66667L6.66667 0Z" fill="#D6D6E0"/>
            </svg>
            <b-button size="min" variant="gray">
                <IconCrypto :coinname="tTo.toLowerCase()" color="color" format="svg" class="mr-1" width="18"/>
                {{tTo}}
            </b-button>
        </template>
    </div>
</template>

<script>
    export default {
        name: "TokenPair",
        props: {
            tFrom: {type: String, default: ''},
            tTo: {type: String, default: ''},
            btnView: {type: Boolean, default: false}
        }

    }
</script>

<style scoped>
</style>
